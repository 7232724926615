@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");

@layer base {
  html {
    font-family: "Open Sans", sans-serif;
  }
}

@layer components {
  .form-screen {
    @apply w-screen h-screen flex flex-col justify-center items-center;
  }

  .form-panel {
    @apply lg:max-w-xl max-w-sm bg-white shadow-xl border rounded-md mx-4 w-full lg:px-12 lg:pb-12 p-6
      lg:mx-0;
  }

  .form-btn {
    @apply text-sm font-bold border inline-flex items-center justify-center outline-none rounded-md w-full p-2 bg-gray-700 text-white;
  }

  .form-btn-back {
    @apply text-sm font-medium space-x-2 border inline-flex items-center justify-center outline-none rounded-md py-1 px-1.5 bg-white;
  }

  .ip-label {
    @apply block mb-0.5 text-sm lg:text-base font-bold text-gray-900;
  }

  .ip-field {
    @apply border border-gray-300 text-gray-900 text-sm font-medium outline-none rounded focus:ring-gray-900 focus:border-gray-900 block w-full px-2.5 py-2;
  }

  .ip-field-select {
    @apply border-y border-r border-gray-300 text-gray-900 text-sm font-medium outline-none rounded-r focus:ring-gray-900 focus:border-gray-900 block w-full px-2.5 py-2;
  }

  .form-title {
    @apply text-lg lg:text-2xl font-bold;
  }

  .form-subtitle {
    @apply text-xs text-gray-600 font-medium lg:text-sm;
  }

  /* === */
  .rbtn {
    @apply border text-xs rounded font-medium px-2 py-1.5 flex items-center justify-center lg:space-x-2;
  }
  .rbtn-primary {
    @apply bg-gray-900 text-white;
  }
  .rbtn-outline {
    @apply text-gray-900;
  }

  /**========= Formulaires */

  .ip-radio {
    @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-black;
  }
  .ip-radio-text {
    @apply ml-2 text-xs font-medium text-gray-900;
  }

  /**============ FORMUALIRAS
  .form-screen {
    @apply h-screen w-screen flex flex-col justify-center items-center;
  }

  .form-panel {
    @apply border shadow-lg rounded w-full flex flex-col max-w-md pb-6;
  }*/
}
